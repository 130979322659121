import one from "./see-breeze/LOGOLOGO.png";


const Logo = () => {

    return(
    <div style={{
        width: "110px",
        height: "110px",
        backgroundSize: "cover",
        backgroundImage: `url(${one})`,
        
      }}>
          
      </div>
    )

}

export default Logo